import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface Version {
  id: number;
  Numero: number;
  date: string;
}

interface VersionsProps {
  formulaireId: number | null;
  updateFormContent: React.Dispatch<React.SetStateAction<any | null>>;
}

const Versions: React.FC<VersionsProps> = ({
  formulaireId,
  updateFormContent,
}) => {
  const [versions, setVersions] = useState<Version[]>([]);

  const handleDelete = async (versionId: number) => {
    const shouldDelete = window.confirm(
      "Est-ce que vous voulez supprimer cette version ?"
    );
    if (shouldDelete) {
      console.log(`Deleting version ${versionId}`);

      try {
        await fetch(
          `${process.env.REACT_APP_SERVER_API}/api/v1/versionning-api/versions/${versionId}`,
          {
            method: "DELETE",
          }
        );
        const updatedVersions = versions.filter(
          (version) => version.id !== versionId
        );
        setVersions(updatedVersions);

        updateFormContent(null);
      } catch (error) {
        console.error("Error deleting version:", error);
      }
    }
  };

  const handleVersionClick = async (versionId: number) => {
    console.log(`Clicked version with ID: ${versionId}`);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/versionning-api/versions/${versionId}/contenu`
      );
      const data = await response.json();
      console.log("Form content data:", data);

      updateFormContent(
        typeof data.contenu === "string"
          ? JSON.parse(data.contenu)
          : data.contenu
      );
    } catch (error) {
      console.error("Error fetching form content:", error);
    }
  };

  useEffect(() => {
    if (formulaireId !== null) {
      fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/versionning-api/versions/formulaire/${formulaireId}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Versions data:", data);
          setVersions(data.versions);
        })
        .catch((error) => console.error("Error fetching versions:", error));
    }
  }, [formulaireId]);

  return (
    <ul className="list-group rounded-0 list-group-flush scrollable-list h-100">
      {versions.map((version) => (
        <li
          key={version.id}
          className="list-group-item d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleVersionClick(version.id)}
        >
          <span>
            Version {version.Numero} : {version.date}
          </span>
          <DeleteIcon
            onClick={() => handleDelete(version.id)}
            style={{ cursor: "pointer" }}
          />
        </li>
      ))}
    </ul>
  );
};

export default Versions;
