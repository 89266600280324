import React, { useState } from "react";
import axios from "axios";
import "../style2.css";

const EditProjectModal: React.FC<{
  selectedOption: string;
  handleClose: () => void;
}> = ({ selectedOption, handleClose }) => {
  const [newUrl, setNewUrl] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(false);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    const isValid = urlRegex.test(url);
    setIsUrlValid(isValid);
    setNewUrl(url);
  };

  const handleEditProject = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_API}/api/v1/projects/modifier/${selectedOption}`,
        {
          URL: newUrl,
        }
      );

      handleClose();
    } catch (error) {
      console.error("Error editing project:", error);
    }
  };

  return (
    <>
      <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modifier projet</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control shorter-input"
                  placeholder="Entrer URL"
                  onChange={handleUrlChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-ok"
                onClick={handleEditProject}
                disabled={!isUrlValid}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-backdrop-full"></div>
    </>
  );
};

export default EditProjectModal;
