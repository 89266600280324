import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

interface DropdownItemProps {
  label: string;
  onClick: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ label, onClick }) => (
  <li>
    <button className="dropdown-item" onClick={onClick}>
      {label}
    </button>
  </li>
);

interface SelectProps {
  onProjectSelect: (projectName: string, projectId: number) => void;
}

const Select: React.FC<SelectProps> = ({ onProjectSelect }) => {
  const [selectedOption, setSelectedOption] = useState("Projets");
  const [projectNames, setProjectNames] = useState<string[]>([]);
  const history = useHistory();

  const handleOptionSelect = async (option: string) => {
    setSelectedOption(option);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API}/api/v1/projects/nom/${option}`
      );
      const project_id = response.data.project_id;
      console.log("Selected Project ID:", project_id); 
      onProjectSelect(option, project_id);
      history.push(`/projects`);
    } catch (error) {
      console.error("Error fetching project ID:", error);
    }
  };

  useEffect(() => {
    const fetchProjectNames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_API}/api/v1/projects`
        );
        setProjectNames(response.data.projets);
      } catch (error) {
        console.error("Error fetching project names:", error);
      }
    };

    fetchProjectNames();
  }, []);

  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ width: "200px" }}
      >
        {selectedOption}
      </button>
      <ul className="dropdown-menu scrollable-dropdown rounded-0" aria-labelledby="dropdownMenuButton1" style={{ width: "200px" }}>
        {projectNames.map((projectName) => (
          <DropdownItem
            key={projectName}
            label={projectName}
            onClick={() => handleOptionSelect(projectName)}
          />
        ))}
      </ul>
    </div>
  );
};

export default Select;