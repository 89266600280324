import React, { useState } from "react";
import Select from "../select/select";
import Ok from "../ok/ok";
import EditProjectModal from "../modifier/modifier";
import AddProjectModal from "../ajouter/ajouter";
import "../style2.css";

const Project: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleProjectSelect = (projectName: string, projectId: number) => {
    setSelectedOption(projectName);
    setSelectedProjectId(projectId);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowEditModal = () => {
    if (selectedOption) {
      setShowEditModal(true);
    } else {
      alert("Vous devez d'abord sélectionner un projet.");
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  return (
    <div className="fullPageBackground">
      <div className="containerStyle">
        <div className="place1">
          <h2>Veuillez sélectionner un projet</h2>
        </div>
        <div className="place2">
          <Select onProjectSelect={handleProjectSelect} />
        </div>
        <div className="place3" style={{ marginTop: "30px" }}>
          <p
            className="edit-link"
            onClick={handleShowEditModal}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              display: "inline",
            }}
          >
            Modifier
          </p>
        </div>

        <div className="buttonContainer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleShowAddModal}
          >
            Ajouter
          </button>
          <Ok
            projectSelected={selectedOption !== ""}
            selectedOption={selectedOption}
            selectedProjectId={selectedProjectId}
          />
        </div>
      </div>

      {showEditModal && (
        <EditProjectModal
          selectedOption={selectedOption}
          handleClose={handleCloseEditModal}
        />
      )}

      {showAddModal && (
        <AddProjectModal handleAdd={handleCloseAddModal} />
      )}
    </div>
  );
};

export default Project;
