// Versionning.tsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../style3.css";
import Formulaires from "../formulaires/formulaires";
import Versions from "../versions/versions";
// @ts-ignore
import { Form, Templates, Components, FormBuilder } from "@formio/react";

try {
  //@ts-ignore
  const { templates, components } = bankeriseCustom.default || bankeriseCustom;

  if (templates && components) {
    Templates.current = templates;
    Components.setComponents(components);
  }
} catch (error) {}

const Versionning = () => {
  const [selectedFormulaireId, setSelectedFormulaireId] = useState<
    number | null
  >(null);
  const [formContent, setFormContent] = useState<string | object | null>(null);
  const [formSelected, setFormSelected] = useState<boolean>(false);
  const { selectedProjectId } = useParams<{ selectedProjectId: string }>();

  const handleFormSelect = (id: number, contenu: string | object) => {
    setSelectedFormulaireId(id);
    setFormContent(typeof contenu === "string" ? JSON.parse(contenu) : contenu);
    setFormSelected(true);
  };

  useEffect(() => {
    if (selectedProjectId) {
      fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/form-api/formulaires/${selectedProjectId}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Formulaire Names:", data.formulaireNames);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [selectedProjectId]);

  return (
    <div>
      <div className="formulaires-container">
        <Formulaires onFormulaireSelect={handleFormSelect} />
      </div>

      <div className="versions-wrapper">
        {formSelected && formContent && typeof formContent === "object" && (
          <div
            style={{
              width: "75%",
              height: "100%",
              border: "1px solid #6610f2",
              borderRadius: "5px",
              padding: "20px",
              overflow: "auto",
              margin: "20px",
            }}
          >
            <Form
              form={formContent}
              options={{
                noAlerts: true,
                readOnly: true,
              }}
            />
          </div>
        )}
        <div className="versions-column2">
          <Versions
            formulaireId={selectedFormulaireId}
            updateFormContent={setFormContent}
          />
        </div>
      </div>
    </div>
  );
};

export default Versionning;
