import React, { FC } from "react"; 
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Project from "./components/2/project/project";
import Versionning from "./components/3/versionning/versionning";

const App: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Project />
        </Route>

        <Route path="/projects">
          <Project />
        </Route>
        <Route path="/versionning/:selectedOption/:selectedProjectId" component={Versionning} />

      </Switch>
    </Router>
  );
}

export default App;
