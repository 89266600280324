import React from "react";
import { Link } from "react-router-dom";

interface OkProps {
  projectSelected: boolean;
  selectedOption: string;
  selectedProjectId: number | null; 
}

const Ok: React.FC<OkProps> = ({
  projectSelected,
  selectedOption,
  selectedProjectId,
}) => {
  let linkDestination = "/projects";

  if (selectedOption && selectedProjectId !== null) {
    linkDestination = `/versionning/${selectedOption}/${selectedProjectId}`;
  }

  return (
    <Link to={linkDestination}>
      <button type="button" className="btn btn-secondary" disabled={!projectSelected}>
        OK
      </button>
    </Link>
  );
};

export default Ok;
