import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../style3.css";

interface RouteParams {
  selectedProjectId: string;
}

interface FormulairesProps {
  onFormulaireSelect: (id: number, contenu: object | string) => void;
}

const Formulaires: React.FC<FormulairesProps> = ({ onFormulaireSelect }) => {
  const [formulaires, setFormulaires] = useState<string[]>([]);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const { selectedProjectId } = useParams<RouteParams>();

  const handleFormClick = async (nom: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/form-api/formulaire-id/${nom}`
      );
      const data = await response.json();
      console.log("Formulaire ID:", data.formulaireId);

      const versionResponse = await fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/versionning-api/versions/formulaire/${data.formulaireId}`
      );
      const versionData = await versionResponse.json();

      setIsOffcanvasOpen(false);
      onFormulaireSelect(data.formulaireId, versionData.versions[0].Contenu);
    } catch (error) {
      console.error("Error fetching formulaire ID:", error);
    }
  };

  const handleCloseOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };

  useEffect(() => {
    if (selectedProjectId) {
      fetch(
        `${process.env.REACT_APP_SERVER_API}/api/v1/form-api/formulaires/${selectedProjectId}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFormulaires(data.formulaireNames);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [selectedProjectId]);

  return (
    <>
      <nav className="navbar ">
        <div className="container-fluid">
          <div className="d-flex">
            <button
              className="btn btn-no-color-change me-2"
              type="button"
              onClick={() => setIsOffcanvasOpen(true)}
            >
              Choisir formulaire
            </button>
          </div>
        </div>
      </nav>

      {isOffcanvasOpen && (
        <div
          className="offcanvas-backdrop"
          onClick={handleCloseOffcanvas}
        ></div>
      )}

      <div
        className={`offcanvas offcanvas-top ${isOffcanvasOpen ? "show" : ""}`}
        tabIndex={-1}
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasTopLabel" className="color">
            Formulaires
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleCloseOffcanvas}
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-group list-group-horizontal">
            {formulaires.map((formulaire, index) => (
              <li
                key={index + "form"}
                className="list-group-item clickable"
                onClick={() => handleFormClick(formulaire)}
              >
                {formulaire}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Formulaires;
